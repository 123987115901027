body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root,
#app_main {
  height: 100%;
}

/* HIDE CONTROLS IN SAFARI */
/* legacy */
video::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0;
}

video::--webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0;
}

/* ios 13 */
video::slotted::-webkit-media-controls-container {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
}

/* ios 14 */
video:host::shadow::-webkit-media-controls-container {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
}
